<template>
  <collection-layout-page
    :page-name="actionResult.text"
    :collectionId="collectionId"
    :collectionKeys="keys"
    :stateCache="stateCache">
    <template v-slot:title>
      <span></span>
    </template>
    <template v-slot:before-table>
      <card-view
        :action="action"
        :actionResult="actionResult"
        :hide-actions="true"
        v-if="action" />
    </template>

  </collection-layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import axios from 'axios'
import CollectionLayoutPage from '@/components/collections/layout/collection-layout-page.vue'
import CardView from './components/card-view.vue'

export default {
  props: ['actionId', 'actionResultKey'],
  data: () => ({
    stateCache: null
  }),
  components: {
    CardView,
    CollectionLayoutPage
  },
  created () {
    this.stateCache = this.getStateCache(`action-collection.${this.actionId}.${this.actionResultKey}`)
  },
  computed: {
    ...mapGetters(['activeMarketId', 'api', 'getStateCache']),
    collectionId () {
      return this.details.collectionId
    },
    keys () {
      return this.details.keys
    },
    action () {
      return this.details.action
    },
    actionResult () {
      return this.details.actionResult
    }
  },
  asyncComputed: {
    details: {
      async get () {
        let allActions = await this.api.actions.getActions({cached: true})
        let action = allActions.find(({actionId}) => actionId === this.actionId)
        if (!action) {
          return this.notFound()
        }

        let {data} = await axios.get(action.url, {
          params: {
            marketId: this.activeMarketId
          }
        })

        let {rows = []} = data || {}

        let actionResult = rows
          .map(row => row.find(({key}) => key === this.actionResultKey))
          .find(actionResult => actionResult)
        if (!actionResult) {
          return this.notFound()
        }

        let {data: {collectionId, keys}} = actionResult

        return {
          collectionId,
          keys,
          action,
          actionResult
        }
      },
      default: {
        collectionId: null,
        keys: null,
        action: null,
        actionResult: null
      }
    }
  },
  methods: {
    notFound () {
      this.$router.replace({
        name: 'not-found',
      })
      return {
        collectionId: null,
        keys: null,
        action: null,
        actionResult: null
      }
    }
  }

}
</script>
